export { matchers } from "/.svelte-kit/generated/client/matchers.js";

export const nodes = [
	() => import("/.svelte-kit/generated/client/nodes/0.js"),
	() => import("/.svelte-kit/generated/client/nodes/1.js"),
	() => import("/.svelte-kit/generated/client/nodes/2.js"),
	() => import("/.svelte-kit/generated/client/nodes/3.js"),
	() => import("/.svelte-kit/generated/client/nodes/4.js"),
	() => import("/.svelte-kit/generated/client/nodes/5.js"),
	() => import("/.svelte-kit/generated/client/nodes/6.js"),
	() => import("/.svelte-kit/generated/client/nodes/7.js"),
	() => import("/.svelte-kit/generated/client/nodes/8.js"),
	() => import("/.svelte-kit/generated/client/nodes/9.js"),
	() => import("/.svelte-kit/generated/client/nodes/10.js"),
	() => import("/.svelte-kit/generated/client/nodes/11.js"),
	() => import("/.svelte-kit/generated/client/nodes/12.js"),
	() => import("/.svelte-kit/generated/client/nodes/13.js"),
	() => import("/.svelte-kit/generated/client/nodes/14.js"),
	() => import("/.svelte-kit/generated/client/nodes/15.js"),
	() => import("/.svelte-kit/generated/client/nodes/16.js"),
	() => import("/.svelte-kit/generated/client/nodes/17.js"),
	() => import("/.svelte-kit/generated/client/nodes/18.js"),
	() => import("/.svelte-kit/generated/client/nodes/19.js"),
	() => import("/.svelte-kit/generated/client/nodes/20.js"),
	() => import("/.svelte-kit/generated/client/nodes/21.js"),
	() => import("/.svelte-kit/generated/client/nodes/22.js"),
	() => import("/.svelte-kit/generated/client/nodes/23.js"),
	() => import("/.svelte-kit/generated/client/nodes/24.js"),
	() => import("/.svelte-kit/generated/client/nodes/25.js"),
	() => import("/.svelte-kit/generated/client/nodes/26.js"),
	() => import("/.svelte-kit/generated/client/nodes/27.js"),
	() => import("/.svelte-kit/generated/client/nodes/28.js"),
	() => import("/.svelte-kit/generated/client/nodes/29.js"),
	() => import("/.svelte-kit/generated/client/nodes/30.js"),
	() => import("/.svelte-kit/generated/client/nodes/31.js"),
	() => import("/.svelte-kit/generated/client/nodes/32.js"),
	() => import("/.svelte-kit/generated/client/nodes/33.js"),
	() => import("/.svelte-kit/generated/client/nodes/34.js"),
	() => import("/.svelte-kit/generated/client/nodes/35.js"),
	() => import("/.svelte-kit/generated/client/nodes/36.js"),
	() => import("/.svelte-kit/generated/client/nodes/37.js"),
	() => import("/.svelte-kit/generated/client/nodes/38.js"),
	() => import("/.svelte-kit/generated/client/nodes/39.js"),
	() => import("/.svelte-kit/generated/client/nodes/40.js")
];

export const server_loads = [];

export const dictionary = {
		"/": [~2],
		"/blog": [~3],
		"/blog/categories/[slug]": [~5],
		"/blog/[slug]": [~4],
		"/conference-landing": [7],
		"/conference": [6],
		"/consulting": [~8],
		"/consulting/contact-center-assessment": [~10],
		"/consulting/contact-center": [~9],
		"/consulting/four-step-approach": [~11],
		"/consulting/leadership-coaching": [~12],
		"/consulting/workforce-management": [~13],
		"/contact-us": [14],
		"/customer-success": [~15],
		"/our-company": [~16],
		"/our-company/careers": [~17],
		"/our-company/environmental-impact": [~18],
		"/our-company/ethics-and-conduct": [~19],
		"/our-company/meet-the-team": [~20],
		"/our-company/meet-the-team/[slug]": [~21],
		"/our-company/our-supply-chain": [~22],
		"/our-company/our-technology": [~23],
		"/our-company/our-videos": [~24],
		"/our-company/our-videos/[slug]": [~25],
		"/our-company/partnerships": [~26],
		"/our-company/privacy-policy": [~27],
		"/our-company/supplier-code-of-conduct": [~28],
		"/solutions": [~29],
		"/solutions/api-connectors": [~30],
		"/solutions/gamification": [~31],
		"/solutions/idp-dashboard": [~32],
		"/solutions/performance-management": [~33],
		"/solutions/quality-management": [~34],
		"/solutions/workforce-management": [~35],
		"/training": [~36],
		"/training/contact-center-essentials": [~37],
		"/training/customized-training": [~38],
		"/training/leadership-training": [~39],
		"/training/workforce-management-training": [~40]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),
};

export { default as root } from "/.svelte-kit/generated/root.svelte";